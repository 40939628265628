/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
//import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

InfoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function InfoDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const theme = useTheme();
  const hr_color = theme.palette.mode === 'dark' ? theme.palette.grey["700"] : theme.palette.grey["300"];

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>About
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton></DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom paragraph>
          本サイトはグランブルーファンタジーに関する攻略サイトやまとめサイトの更新情報を一覧にして提供しています。
        </Typography>
        <Typography gutterBottom paragraph>
          サイト右上の<SettingsIcon sx={{fontSize: "16px", verticalAlign: "text-bottom"}}/>をクリックすると設定ウィンドウが開きます。コンテンツの見た目や表示したいサイトの選択など、細かな設定を行うことができます。
        </Typography>
        <div
        css={css`
          border-bottom: 1px solid ${hr_color};
          padding-top: 5px;
          margin-bottom: 15px;
        `}></div>
        <Typography gutterBottom paragraph>
          <Typography variant="subtitle1">
            本サイトの詳細
          </Typography>
          <ul css={css`
            padding-inline-start: 20px;`}>
            <li>{"約6時間以内に投稿されたコンテンツには\"NEWマーク\"が表示されます"}</li>
            <li>{"各カテゴリー毎に新着順で最大136件のコンテンツが表示されます"}</li>
            <li>{"各サイトの新着情報は約30分で更新されます"}</li>
            <li>{"ユーザーの各種設定や現在どのカテゴリが選択されているのか等の情報は自動でブラウザ内に保存され、次回訪問時に適用されます"}</li>
            <li>{"本サイトを30分間開いたままの場合は、再アクセス時に自動でリロードされます"}</li>
          </ul>
        </Typography>
        <div
        css={css`
          border-bottom: 1px solid ${hr_color};
          padding-top: 5px;
          margin-bottom: 15px;
        `}></div>
        <Typography variant="subtitle1">
          本サイトの運営者
        </Typography>
        <div css={css`
          padding-left: 15px;
          padding-top: 10px;
        `}>
          <Box>
            <Link href="https://twitter.com/KazuYagami1" color="inherit" underline="hover" target="_blank" >
              <Typography variant="subtitle1" sx={{color:theme.palette.primary.main}}>
                KazuYagami
              </Typography>
              <Typography variant="subtitle2" sx={{color:theme.palette.text.secondary}}>
                雰囲気でグラブルをプレイしている
              </Typography>
            </Link>
          </Box>
      　</div>
        <Typography variant="subtitle1">
          おすすめ便利サイト
        </Typography>
        <div css={css`
          padding-left: 15px;
          padding-top: 10px;
        `}>
          <Box sx={{pb:2}}>
            <Link href="https://gbf.life" color="inherit" underline="hover" target="_blank" >
              <Typography variant="subtitle1" sx={{color:theme.palette.primary.main}}>
                グラブルTwitter救援！
              </Typography>
              <Typography variant="subtitle2" sx={{color:theme.palette.text.secondary}}>
                グラブルの救援IDを簡単に取得できる
              </Typography>
            </Link>
          </Box>
          <Box sx={{pb:2}}>
            <Link href="https://friend.gbf.life" color="inherit" underline="hover" target="_blank" >
              <Typography variant="subtitle1" sx={{color:theme.palette.primary.main}}>
                グラブルフレンド募集！
              </Typography>
              <Typography variant="subtitle2" sx={{color:theme.palette.text.secondary}}>
                フレンドを検索/募集できる
              </Typography>
            </Link>
          </Box>
          <Box>
            <Link href="https://gbf-bbs.com/" color="inherit" underline="hover" target="_blank" >
              <Typography variant="subtitle1" sx={{color:theme.palette.primary.main}}>
                グラブル騎空団募集掲示板
              </Typography>
              <Typography variant="subtitle2" sx={{color:theme.palette.text.secondary}}>
                騎空団を探したり、団員を募ったりできる
              </Typography>
            </Link>
          </Box>
      　</div>
      </DialogContent>
    </Dialog>
  );
}
