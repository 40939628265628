import React from "react";
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import SimpleSettings from './SimpleSettings';
import AdvancedSettings from './AdvancedSettings';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import InfoDialog from './InfoDialog';
import { useTheme } from '@mui/material/styles';

export default function SettingsButton(props: Props) {
  const handleClose = (value) => {
    setOpen(false);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const DrawerItem = styled('div')(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const theme = useTheme();

  return (
    <React.Fragment>
      <Box>
        <IconButton onClick={handleClickOpen} edge="end" color="inherit" aria-label="menu" sx={{mr: 0}}>
          <InfoIcon />
        </IconButton>
        <IconButton onClick={toggleDrawer('right', true)} edge="end" color="inherit" aria-label="menu" sx={{mr: 0}}>
          <SettingsIcon />
        </IconButton>
      </Box>
      {['right'].map((anchor) => (
        <div key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <DrawerItem
              sx={{  width: 450,
              [theme.breakpoints.down('sm')]: {
                width: 250,
              }, }}
              role="presentation"
            >
                <SimpleSettings/>
            </DrawerItem>
          </Drawer>
        </div>
      ))}
      <InfoDialog
        open={open}
        onClose={handleClose}
      />
   </React.Fragment>
  );
};
