import React, {useCallback, useState, useEffect, useContext} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Articles from './Articles';
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import { SiteListContext, ViewContext, DisplayContext } from './App';
import { useTheme } from '@mui/material/styles';
import { DEFAULT_READ_NUM } from './constants.js';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    threshold: 300,
  });

  return (
    <Slide  appear={false} direction="down" in={!trigger}>
      {children}
    </Slide >
  );
}
HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Content(props: Props) {
  /* is Mobile? */
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  const {siteList, siteListDispatch} = useContext(SiteListContext);
  const {view, viewDispatch} = useContext(ViewContext);
  const {display, displayDispatch} = useContext(DisplayContext);

  const [value, setValue] = React.useState(localStorage.getItem("currentTab") ? Number(localStorage.getItem("currentTab")) : 2);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if(newValue == 0 && window.kojin_data){
      setKojinData(window.kojin_data.slice(0,DEFAULT_READ_NUM))
      window.kojin_data_done = kojinData.length
      setIsActiveObserver1(true);
    }
    if(newValue == 1 && window.kigyou_data){
      setKigyouData(window.kigyou_data.slice(0,DEFAULT_READ_NUM))
      window.kigyou_data_done = kigyouData.length
      setIsActiveObserver2(true);
    }
    if(newValue == 2 && window.matome_data){
      setMatomeData(window.matome_data.slice(0,DEFAULT_READ_NUM))
      window.matoma_data_done = matomeData.length
      setIsActiveObserver3(true);
    }
    if(newValue == 3 && window.all_data){
      window.all_data.sort(function(e1, e2){return Number(e1.name) - Number(e2.name)});
      setAllData(window.all_data.slice(0,DEFAULT_READ_NUM))
      window.all_data_done = allData.length
      setIsActiveObserver4(true);
    }
    localStorage.setItem("currentTab",newValue);
    window.scrollTo({
      top: 0,
    });
    setValue(newValue);
  };

  const theme = useTheme();
  const [number, setNumBer] = React.useState(0);

  const [matomeData, setMatomeData] = React.useState([]);
  const [kojinData, setKojinData] = React.useState([]);
  const [kigyouData, setKigyouData] = React.useState([]);
  const [allData, setAllData] = React.useState([]);
  const [isActiveObserver1, setIsActiveObserver1] = React.useState(true);
  const [isActiveObserver2, setIsActiveObserver2] = React.useState(true);
  const [isActiveObserver3, setIsActiveObserver3] = React.useState(true);
  const [isActiveObserver4, setIsActiveObserver4] = React.useState(true);

  useEffect(() => {
    if(window.kojin_data){
      setKojinData(window.kojin_data.slice(0,DEFAULT_READ_NUM))
      window.kojin_data_done = kojinData.length
      setIsActiveObserver1(true);
    }
    if(window.kigyou_data){
      setKigyouData(window.kigyou_data.slice(0,DEFAULT_READ_NUM))
      window.kigyou_data_done = kigyouData.length
      setIsActiveObserver2(true);
    }
    if(window.matome_data){
      setMatomeData(window.matome_data.slice(0,DEFAULT_READ_NUM))
      window.matoma_data_done = matomeData.length
      setIsActiveObserver3(true);
    }
    if(window.kojin_data && window.kigyou_data && window.matome_data && window.all_data && window.all_data.length){
      window.all_data.sort(function(e1, e2){return Number(e1.name) - Number(e2.name)});
      setAllData(window.all_data.slice(0,DEFAULT_READ_NUM))
      window.all_data_done = allData.length
      setIsActiveObserver4(true);
    }
  },[siteList, view, display])

  useEffect(() => {
    const updateMatomeAritcles = () => {
      setMatomeData(window.matome_data.slice(0,DEFAULT_READ_NUM))
      window.matoma_data_done = matomeData.length
    };
    const updateKojinAritcles = () => {
      setKojinData(window.kojin_data.slice(0,DEFAULT_READ_NUM))
      window.kojin_data_done = kojinData.length
    };
    const updateKIgyouAritcles = () => {
      setKigyouData(window.kigyou_data.slice(0,DEFAULT_READ_NUM))
      window.kigyou_data_done = kigyouData.length
    };
    const updateAllAritcles = () => {
      window.all_data.sort(function(e1, e2){return Number(e1.name) - Number(e2.name)});
      setAllData(window.all_data.slice(0,DEFAULT_READ_NUM))
      window.all_data_done = allData.length
    };
    window.updateMatomeAritcles = updateMatomeAritcles
    window.updateKojinAritcles = updateKojinAritcles
    window.updateKIgyouAritcles = updateKIgyouAritcles
    window.updateAllAritcles = updateAllAritcles
  },[])

  const fetchKojinData = useCallback(async () => {
   if(window.kojin_data){
     setKojinData([...kojinData,...(window.kojin_data.slice(kojinData.length,kojinData.length + DEFAULT_READ_NUM))])
     if (kojinData.length === window.kojin_data.length) {
       return setIsActiveObserver1(false);
     }
   }
  });

  const fetchKigyouData = useCallback(async () => {
    if(window.kigyou_data){
      setKigyouData([...kigyouData,...(window.kigyou_data.slice(kigyouData.length,kigyouData.length + DEFAULT_READ_NUM))])
      if (kigyouData.length === window.kigyou_data.length) {
        return setIsActiveObserver2(false);
      }
    }
   }, [kigyouData]);

  const fetchMatomeData = useCallback(async () => {
    if(window.matome_data){
      setMatomeData([...matomeData,...(window.matome_data.slice(matomeData.length,matomeData.length + DEFAULT_READ_NUM))])
      if (matomeData.length === window.matome_data.length) {
        return setIsActiveObserver3(false);
      }
    }
   }, [matomeData]);

   const fetchAllData = useCallback(async () => {
     if(window.kojin_data && window.kigyou_data && window.matome_data && window.all_data){
       setAllData([...allData,...(window.all_data.slice(allData.length,allData.length + DEFAULT_READ_NUM))])
       if (allData.length != 0 && allData.length === window.all_data.length) {
         return setIsActiveObserver4(false);
       }
     }
    }, [allData]);

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <Box position="sticky" sx={{ zIndex:100, top:0, width: '100%', backgroundColor: theme.palette.background.stickyTab, backdropFilter: "blur(20px)"}}>
          { isMobile ?
            <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            scrollButtons="auto">
              <Tab label="すべて" value={3} />
              <Tab label="ブログ" value={0}/>
              <Tab label="企業Wiki" value={1}/>
              <Tab label="まとめ" value={2}/>
            </Tabs>
            :
            <Tabs
            value={value}
            onChange={handleChange}
            scrollButtons="auto" centered>
              <Tab label="すべて" value={3} />
              <Tab label="ブログ" value={0}/>
              <Tab label="企業Wiki" value={1}/>
              <Tab label="まとめサイト" value={2}/>
            </Tabs>
          }
        </Box>
      </HideOnScroll>
      <TabPanel value={value} index={3}>
        <Articles data={allData} dataName="all" dataUpdate={fetchAllData} isActiveObserver={isActiveObserver4}/>
      </TabPanel>
      <TabPanel value={value} index={0}>
        <Articles data={kojinData} dataName="kojin" dataUpdate={fetchKojinData} isActiveObserver={isActiveObserver1}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Articles data={kigyouData} dataName="kigyou" dataUpdate={fetchKigyouData} isActiveObserver={isActiveObserver2}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Articles data={matomeData} dataName="matome" dataUpdate={fetchMatomeData} isActiveObserver={isActiveObserver3}/>
      </TabPanel>
    </React.Fragment>
  )
}

export default Content
