import React, { useContext, useState } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import {DisplayContext} from './App';
//import moment from 'moment'
//import 'moment/locale/ja'
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import relativeTime from 'dayjs/plugin/relativeTime'
import Grow from '@mui/material/Grow';
import Grid from '@mui/material/Grid';
import { NewTabContext } from './App';
import './new-mark.css';
import FiberNewIcon from '@mui/icons-material/FiberNewTwoTone';
import { NEW_CONTENT_MIN_LIMIT } from './constants.js';

export default React.memo(function ArticleSmall(props) {
  const {newTab, newTabDispatch} = useContext(NewTabContext);
  const {display, displayDispatch} = useContext(DisplayContext);
  const theme = useTheme();
  let data = props.content.metadata;
  let image_url = `https://rss-img.ezmemo.net/c_limit,f_webp,q_auto:low,w_70/${data.image_id}.webp`;

  function decode(encodedString) {
    var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    var translate = {
        "nbsp":" ",
        "amp" : "&",
        "quot": "\"",
        "lt"  : "<",
        "gt"  : ">"
    };
    return encodedString.replace(translate_re, function(match, entity) {
        return translate[entity];
    }).replace(/&#(\d+);/gi, function(match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
    });
  }

  dayjs.extend(relativeTime)
  dayjs.locale('ja');
  let current_date = dayjs();
  let date_date = dayjs(data.date);
  let fromNow = date_date.fromNow();
  let diff_date = current_date.diff(date_date,"minute");

  return (
  <Grow in={true}>
    <Card sx={{ marginLeft:"20px", marginBottom:2 }} >
      <Link sx={{ height:"100%" }} href={data.link} color="inherit" target= {newTab=="newTab" ? "_blank" : "_self"} underline="hover" rel="noopener">
        <Box sx={{ position: "relative", height:"100%", width: "100%", display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ position: "relative", width: display.includes("image") ?  "20%" : "0%"}}>
            <Box sx={{ position: "absolute", width: "100%", height: "100%"}}>
              <CardMedia decoding="async" loading="lazy"
                sx={{ height: "inherit",
                  display: display.includes("image") ?  "" : "none",
                  [theme.breakpoints.down('sm')]: {
                    objectFit: "contain"
                  }, }}
                component={display.includes("image") ?  "img" : "div"}
                image={image_url}
              />
            </Box>
          </Box>
          <CardContent sx={{ width: "80%", flex: '1 0 auto' }}>
            <Box component="div" zIndex={90} sx={{ position: "absolute" }}>
              <Box sx={{ fontSize: "12px",color: theme.palette.mode == "dark" ? "orangered" : "red", fontWeight: 600, display: diff_date < NEW_CONTENT_MIN_LIMIT ? "" : "none", position: "absolute", top: "-13px", left:"-6px" }}>
                {"New!"}
              </Box>
            </Box>
            <Typography variant="h7" component="div" sx={{
              mb: display.every(el => el == "title" || el == "image") ? 0 : 1,
              display: display.includes("title") ?  "-webkit-box" : "none",
              [theme.breakpoints.down('sm')]: {
                fontSize: "0.80em"
              },
              height: "3em",
              overflow: "hidden",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",}}>
              {decode(data.title)}
            </Typography>
            <Typography sx={{
              color: theme.palette.mode == "dark" ? "#bebebe" : "#7f7f7f",
              display: display.includes("description") ?  "-webkit-box" : "none",
              overflow: "hidden",
              mb: display.every(el => el == "title" || el == "image" || el == "description") ? 0 : 1,
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",}} variant="caption" color="text.primary" component="div">
              {decode(data.description)}
            </Typography>
            <Grid container justifyContent="flex-start">
              <Grid item xs={9} zeroMinWidth>
                <Typography sx={{
                  color: theme.palette.mode == "dark" ? theme.palette.grey["700"] : theme.palette.grey["500"],
                  display: display.includes("creator") ?  "-webkit-box" : "none",
                  overflow: "hidden",
                  fontSize: "0.70em",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",}}
                   align="left" variant="subtitle2" color="text.secondary" component="div">
                  {data.creator}
                </Typography>
              </Grid>
              <Grid item xs={3} zeroMinWidth>
                <Typography sx={{
                  color: theme.palette.mode == "dark" ? theme.palette.grey["700"] : theme.palette.grey["500"],
                  fontSize: "0.70em",
                  display: display.includes("date") ?  "" : "none",}} align="right" variant="subtitle2" color="text.secondary" component="div">
                  { fromNow }
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Link>
    </Card>
  </Grow>
  )
})
