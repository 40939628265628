import './App.css';
import Grid from '@mui/material/Grid';
import Header from './Header';
import Content from './Content';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import { useEffect, useState } from "react";

export const OptionsContext = React.createContext();
export const ViewContext = React.createContext();
export const DisplayContext = React.createContext();
export const SiteListContext = React.createContext();
export const NewTabContext = React.createContext();

function ScrollTop(props) {
  const {children} = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
};


function App(props) {

  /* is Mobile? */
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;

  /* View option */

   const viewReducer = (state, action) => {
     if (action === 'SET_LIST') {
       localStorage.setItem("view",'list');
       return "list";
     }
     else if (action === 'SET_MODULE') {
       localStorage.setItem("view",'module');
       return "module";
     }
     else if (action === 'SET_QUILT') {
       localStorage.setItem("view",'quilt');
       return "quilt";
     }
   };

   if(!localStorage.getItem("view")){
      if (isMobile) {
        localStorage.setItem("view", "list");
      }else{
        localStorage.setItem("view", "module");
      }
   }

   const [view, viewDispatch] = useReducer(viewReducer, localStorage.getItem("view") );

   /* Display items option */

   const displayReducer = (state, action) => {
     let new_state = state.concat();
     if (action === 'SET_IMAGE') {
       new_state.push('image');
       localStorage.setItem("display_items",JSON.stringify(new_state));
     }
     else if (action === 'UNSET_IMAGE') {
       new_state = state.filter(function( item ) {
         return item != 'image';
       });
       localStorage.setItem("display_items",JSON.stringify(new_state));
     }
     else if (action === 'SET_TITLE') {
       new_state.push('title');
       localStorage.setItem("display_items",JSON.stringify(new_state));
     }
     else if (action === 'UNSET_TITLE') {
       new_state = state.filter(function( item ) {
         return item != 'title';
       });
       localStorage.setItem("display_items",JSON.stringify(new_state));
     }
     else if (action === 'SET_DESCRIPTION') {
       new_state.push('description');
       localStorage.setItem("display_items",JSON.stringify(new_state));
     }
     else if (action === 'UNSET_DESCRIPTION') {
       new_state = state.filter(function( item ) {
         return item != 'description';
       });
       localStorage.setItem("display_items",JSON.stringify(new_state));
     }
     else if (action === 'SET_CREATOR') {
       new_state.push('creator');
       localStorage.setItem("display_items",JSON.stringify(new_state));
     }
     else if (action === 'UNSET_CREATOR') {
       new_state = state.filter(function( item ) {
         return item != 'creator';
       });
       localStorage.setItem("display_items",JSON.stringify(new_state));
     }
     else if (action === 'SET_DATE') {
       new_state.push('date');
       localStorage.setItem("display_items",JSON.stringify(new_state));
     }
     else if (action === 'UNSET_DATE') {
       new_state = state.filter(function( item ) {
         return item != 'date';
       });
       localStorage.setItem("display_items",JSON.stringify(new_state));
     }
     return new_state;
   };

   if(!localStorage.getItem("display_items")){
      if (isMobile) {
        localStorage.setItem("display_items",JSON.stringify(["image","title","date","creator"]));
      }else{
        localStorage.setItem("display_items",JSON.stringify(["image","title","date","creator"]));
      }
   }

   const [display, displayDispatch] = useReducer(displayReducer, JSON.parse(localStorage.getItem("display_items")));

   /* Dark Mode option */
   const reducer = (state, action) => {
     if (action === 'DARK_MODE_ON') {
       localStorage.setItem("darkMode",true);
       return {darkmode: 'true'};
     } else {
       localStorage.setItem("darkMode",false);
       return {darkmode: 'false'};
     }
   };

   if(!localStorage.getItem("darkMode")){
     localStorage.setItem("darkMode",false);
   }

   const [options, dispatch] = useReducer(reducer, {darkmode: localStorage.getItem("darkMode")});

   /* Site list option */
   const siteListReducer = (state, action) => {
     let new_state = state.concat();
     if (action.match(/^SET_/)) {
       let site_name = action.slice(4);
       new_state.push(site_name);
       localStorage.setItem("siteList",JSON.stringify(new_state));
     }
     else if (action.match(/^UNSET_/)) {
       let site_name = action.slice(6);
       new_state = state.filter(function( item ) {
         return item != site_name;
       });
       localStorage.setItem("siteList",JSON.stringify(new_state));
     }
     return new_state;
   };

   if(!localStorage.getItem("siteList")){
     localStorage.setItem("siteList",JSON.stringify(["サカナンのゆるふわゲーム攻略","いっけぐらぶる","GameWith","ミニゴブ速報","グラブルまとめ三姉妹","いすたるしあ通信","日々是グラブる","りふどらのグラブル攻略日記","tkグランのグラブル攻略日記","今日も何処かで世界を救う","たるたろす","なるおさんのグラブル生活","ばぶ速"]));
   }

   const [siteList, siteListDispatch] = useReducer(siteListReducer, JSON.parse(localStorage.getItem("siteList")));

   /* How open option */
   const newTabReducer = (state, action) => {
      if (action === 'NEW_TAB') {
        localStorage.setItem("newTab","newTab");
        return "newTab";
      } else {
        localStorage.setItem("newTab","sameTab");
        return "sameTab";
      }
   }
   if(!localStorage.getItem("newTab")){
      if (isMobile) {
        localStorage.setItem("newTab","sameTab");
      } else {
        localStorage.setItem("newTab","newTab");
      }
   }
   const [newTab, newTabDispatch] = useReducer(newTabReducer, localStorage.getItem("newTab"));

   const theme = React.useMemo(
     () => {
       return createTheme({
         typography: {
           "fontFamily": `"Kosugi Maru", sans-serif`,
         },
         palette: {
           mode: options.darkmode == "true" ? 'dark' : 'light',
           background:{
             stickyTab: localStorage.getItem("darkMode") === "true" ? "#121212d6" : "#ffffffd9"
           }
         },
         components: {
           MuiCardContent :{
             styleOverrides: {
               root:{
                  "&:last-child": {
                   padding: '10px'
                 },
               }
             }
           },
           MuiFab :{
             styleOverrides: {
               root: options.darkmode != "true" ? {
                 backgroundColor: "#6cbefc",
                 color: "white",
                 "&:hover": {
                   backgroundColor: '#aadaff'
                 }
               }
               :{
                 backgroundColor: "#999999",
                 "&:hover": {
                   backgroundColor: '#dfdfdf'
                 }
               }
             }
           }
         }
       });
     },
     [options],
   );

  return (
      <div>
        <OptionsContext.Provider value={{ options, dispatch }}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
              <Grid container direction="column">
                  <DisplayContext.Provider value={{ display, displayDispatch }}>
                  <ViewContext.Provider value={{ view, viewDispatch }}>
                  <SiteListContext.Provider value={{ siteList, siteListDispatch }}>
                  <NewTabContext.Provider value={{ newTab, newTabDispatch }}>
                    <Header />
                    <div id="header">
                      <Content sx={{ pr:0, pl:0 }} />
                    </div>
                  </NewTabContext.Provider>
                  </SiteListContext.Provider>
                  </ViewContext.Provider>
                  </DisplayContext.Provider>
                <ScrollTop {...props}>
                  <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                  </Fab>
                </ScrollTop>
              </Grid>
          </ThemeProvider>
        </OptionsContext.Provider>
      </div>
  );
}
export default App;
