import React from "react";
import { AppBar, Toolbar, Typography, Grid} from "@mui/material";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import SettingsButton from './SettingsButton';
import CellTowerIcon from '@mui/icons-material/CellTower';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

//const SettingsButton = lazy(() => import('./SettingsButton'));

export default function Header(props: Props) {

  return (
      <AppBar position="relative">
        <Toolbar variant="dense" sx={{width:"auto", pr: 0}}>
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <Grid container alignItems="center">
                <Typography variant="h6" edge="start" color="inherit" component="span">
                  グラブルまとめ！
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container justifyContent="flex-end">
                <SettingsButton />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
  );
};
